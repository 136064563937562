import { MESSAGES } from '@/services/socket.constants';

type OnMessageHandler = Worker['onmessage'];
type PostMessageHandler = Worker['postMessage'];

/** The WebSocket class, or at least one with a suitably-mocked constructor. */
interface WebSocketIsh {
  new(url: string, protocols?: string | string[]): WebSocket;
}

let postMessage: PostMessageHandler;
let WebSocketCls: WebSocketIsh;

/**
 * Function used to provide a pluggable interface that allows the postMessage function and WebSocket class to be
 * mocked in tests.
 *
 * @param {Function} postMessageFunc - Function worker should use to post messages back to the parent.
 * @param {Function} webSocketCls - constructor function for creating a WebSocket.
 * @returns {Function} to call when a message is received by this worker thread.
 */
function ctor(postMessageFunc: PostMessageHandler, webSocketCls: WebSocketIsh,): OnMessageHandler {
  postMessage = postMessageFunc;
  WebSocketCls = webSocketCls;
  return onmessage;
}

/**
 * Main HTML5 message handler for this WebWorker thread to receive messages from its parent thread. All messages are
 * routed to the correct handler function based on the type specified in the payload.
 *
 * @param {Object} message - HTML5 message received from the main AngularJS app thread
 * @param {Object} message.data - Data payload for this message, as sent from the AngularJS app thread
 * @param {string} message.data.type - Type of message, to use for routing to the appropriate handler.
 * @param {string|Object} message.data.payload - Data payload for the message (varies by type)
 */
function onmessage(message: MessageEvent) {
  const type = message.data.type;
  const data = message.data.payload;

  switch (type) {
    case MESSAGES.COMMAND_OPEN:
      url = data.url;
      csrfToken = data.csrfToken;
      connectSocket();
      break;
    case MESSAGES.COMMAND_CLOSE:
      closeSocket();
      break;
    case MESSAGES.COMMAND_TRANSMIT:
      transmitSocket(data);
      break;
  }
}

let socket: WebSocket | undefined;
let url;
let csrfToken;
const reconnectInitialTimeout = 500;
const reconnectMaxTimeout = 1000 * 60;
let reconnectAttempts = 0;
let reconnectTimer;
const normalCloseCode = 1000;
const reconnectableCloseCodes = [4000];

/**
 * Open the websocket connection
 */
function connectSocket() {
  try {
    clearReconnect();
    closeSocket();

    // Websockets are vulnerable to CSRF. Since headers can't be specified we use the "subprotocols" argument to
    // pass along the CSRF token. See the WebSocketClientApplication for more documentation.
    socket = new WebSocketCls(url, csrfToken);
    socket.onmessage = onSocketMessage;
    socket.onopen = onSocketOpen;
    socket.onclose = onSocketClose;
    socket.onerror = onSocketError;
  } catch (e) {
    onSocketError();
  }
}

/**
 * Close the existing websocket
 */
function closeSocket() {
  if (!socket) {
    return;
  }

  clearReconnect();

  socket.close();
  socket.onmessage = undefined;
  socket.onopen = undefined;
  socket.onclose = undefined;
  socket.onerror = undefined;
  socket = undefined;
}

/**
 * Send data via the websocket. Does nothing if socket is not already open.
 *
 * @param {String} data - String data to send. No additional encoding is done to the data.
 */
function transmitSocket(data) {
  if (socket) {
    socket.send(data);
  }
}

/**
 * Sends message data received via the websocket back to the main AngularJS app.
 *
 * @param {Object} event - Message received via websocket
 * @param {Object} event.data - Data portion of received message
 */
function onSocketMessage(event: MessageEvent) {
  sendToParent(MESSAGES.ON_WEBSOCKET_DATA, event.data);
}

/**
 * Sends notification to main AngularJS app that websocket was successfully opened
 */
function onSocketOpen(_: Event) {
  reconnectAttempts = 0;

  sendToParent(MESSAGES.ON_OPEN_COMPLETE);
}

/**
 * Sends notification to main AngularJS app that websocket was closed
 */
function onSocketClose(event: CloseEvent) {
  sendToParent(MESSAGES.ON_CLOSE_COMPLETE, { code: event.code, wasClean: event.wasClean });

  if (event.code !== normalCloseCode || reconnectableCloseCodes.indexOf(event.code) > -1) {
    reconnect();
  }
}

/**
 * Sends notification to main AngularJS app that websocket encountered an error.
 */
function onSocketError(_?: Event) {
  sendToParent(MESSAGES.ON_ERROR);
}

/**
 * When the socket drops not due to a close, automatically attempt to reconnect.
 */
function reconnect() {
  closeSocket();

  const backoffDelay = getBackoffDelay(++reconnectAttempts);
  sendToParent(MESSAGES.COMMAND_LOG, 'Reconnecting in ' + (backoffDelay / 1000) + ' seconds');

  reconnectTimer = setTimeout(connectSocket, backoffDelay);
}

/**
 * This clears the timeout that is set up if the socket drops due to network
 * issues or other non-client initiated socket closure
 */
function clearReconnect() {
  if (reconnectTimer) {
    clearTimeout(reconnectTimer);
    reconnectTimer = undefined;
  }
}

/**
 * Calculate a delay to use before reconnecting, based on the number of attempts already made.
 * Excerpted from https://github.com/PatrickJS/angular-websocket
 * Exponential Backoff Formula by Prof. Douglas Thain
 *  http://dthain.blogspot.co.uk/2009/02/exponential-backoff-in-distributed.html
 *
 * @param {number} attempt - count of previous attempts
 * @returns {number} Milliseconds to wait before next attempt
 */
function getBackoffDelay(attempt) {
  const R = Math.random() + 1;
  const T = reconnectInitialTimeout;
  const F = 2;
  const N = attempt;
  const M = reconnectMaxTimeout;

  return Math.floor(Math.min(R * T * Math.pow(F, N), M));
}

/**
 * Helper function to pass messages back to the parent thread
 *
 * @param {String} type - Type of message
 * @param {Object|String} [payload] - Data to send
 */
function sendToParent(type: MESSAGES, payload?) {
  postMessage({ type, payload });
}

export default ctor;
